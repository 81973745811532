import { default as _91_46_46_46slug_93IoQXAS8axJMeta } from "/builds/filmfonds/filmfonds-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93UXVrn1nkPcMeta } from "/builds/filmfonds/filmfonds-frontend/pages/actueel/[...slug].vue?macro=true";
import { default as index6lYiPPbS6kMeta } from "/builds/filmfonds/filmfonds-frontend/pages/actueel/index.vue?macro=true";
import { default as _91_46_46_46slug_93GWxB4EK12UMeta } from "/builds/filmfonds/filmfonds-frontend/pages/agenda/[...slug].vue?macro=true";
import { default as indexETOMiAOMzoMeta } from "/builds/filmfonds/filmfonds-frontend/pages/agenda/index.vue?macro=true";
import { default as deadlinesg9HkGQGESMMeta } from "/builds/filmfonds/filmfonds-frontend/pages/deadlines.vue?macro=true";
import { default as indexzN5Uh0KIqEMeta } from "/builds/filmfonds/filmfonds-frontend/pages/index.vue?macro=true";
import { default as _91category_93DSw60XztCaMeta } from "/builds/filmfonds/filmfonds-frontend/pages/subsidies/[category].vue?macro=true";
import { default as _91_46_46_46slug_93mLk8Pg9GeiMeta } from "/builds/filmfonds/filmfonds-frontend/pages/subsidies/subsidie/[subsidy]/[...slug].vue?macro=true";
import { default as indexX4XEVQFs6XMeta } from "/builds/filmfonds/filmfonds-frontend/pages/subsidies/subsidie/[subsidy]/index.vue?macro=true";
import { default as _91_46_46_46slug_936QH9FI1zoZMeta } from "/builds/filmfonds/filmfonds-frontend/pages/team/[...slug].vue?macro=true";
import { default as indexIIYAv56sErMeta } from "/builds/filmfonds/filmfonds-frontend/pages/team/index.vue?macro=true";
import { default as toegewezen_45bijdragenCETHoeosgjMeta } from "/builds/filmfonds/filmfonds-frontend/pages/toegewezen-bijdragen.vue?macro=true";
import { default as zoekQxy6RzHOogMeta } from "/builds/filmfonds/filmfonds-frontend/pages/zoek.vue?macro=true";
import { default as component_45stubBEdx3VQajNMeta } from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubBEdx3VQajN } from "/builds/filmfonds/filmfonds-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/[...slug].vue")
  },
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/[...slug].vue")
  },
  {
    name: "actueel-slug___en",
    path: "/en/updates/:slug()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/actueel/[...slug].vue")
  },
  {
    name: "actueel-slug___nl",
    path: "/actueel/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/actueel/[...slug].vue")
  },
  {
    name: "actueel___en",
    path: "/en/updates",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/actueel/index.vue")
  },
  {
    name: "actueel___nl",
    path: "/actueel",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/actueel/index.vue")
  },
  {
    name: "agenda-slug___en",
    path: "/en/agenda/:slug()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/agenda/[...slug].vue")
  },
  {
    name: "agenda-slug___nl",
    path: "/agenda/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/agenda/[...slug].vue")
  },
  {
    name: "agenda___en",
    path: "/en/agenda",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/agenda/index.vue")
  },
  {
    name: "agenda___nl",
    path: "/agenda",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/agenda/index.vue")
  },
  {
    name: "deadlines___en",
    path: "/en/deadlines",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/deadlines.vue")
  },
  {
    name: "deadlines___nl",
    path: "/deadlines",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/deadlines.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/index.vue")
  },
  {
    name: "subsidies-category___en",
    path: "/en/funding/:category()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/subsidies/[category].vue")
  },
  {
    name: "subsidies-category___nl",
    path: "/subsidies/:category()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/subsidies/[category].vue")
  },
  {
    name: "subsidies-subsidie-subsidy-slug___en",
    path: "/en/funding/fund/:subsidy()/:slug()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/subsidies/subsidie/[subsidy]/[...slug].vue")
  },
  {
    name: "subsidies-subsidie-subsidy-slug___nl",
    path: "/subsidies/subsidie/:subsidy()/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/subsidies/subsidie/[subsidy]/[...slug].vue")
  },
  {
    name: "subsidies-subsidie-subsidy___en",
    path: "/en/funding/fund/:subsidy()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/subsidies/subsidie/[subsidy]/index.vue")
  },
  {
    name: "subsidies-subsidie-subsidy___nl",
    path: "/subsidies/subsidie/:subsidy()",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/subsidies/subsidie/[subsidy]/index.vue")
  },
  {
    name: "team-slug___en",
    path: "/en/team/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/team/[...slug].vue")
  },
  {
    name: "team-slug___nl",
    path: "/team/:slug(.*)*",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/team/[...slug].vue")
  },
  {
    name: "team___en",
    path: "/en/team",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/team/index.vue")
  },
  {
    name: "team___nl",
    path: "/team",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/team/index.vue")
  },
  {
    name: "toegewezen-bijdragen___en",
    path: "/en/toegewezen-bijdragen",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/toegewezen-bijdragen.vue")
  },
  {
    name: "toegewezen-bijdragen___nl",
    path: "/toegewezen-bijdragen",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/toegewezen-bijdragen.vue")
  },
  {
    name: "zoek___en",
    path: "/en/search",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/zoek.vue")
  },
  {
    name: "zoek___nl",
    path: "/zoek",
    component: () => import("/builds/filmfonds/filmfonds-frontend/pages/zoek.vue")
  },
  {
    name: component_45stubBEdx3VQajNMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubBEdx3VQajN
  }
]